<template>
  <div>
    <div class="page-title">
      <h3>{{$t('planPage.Plan')}}</h3>
      <h4>{{info.bank | currencyView}}</h4>
    </div>
    <loader v-if="loading"/>
    <p class="center" v-else-if="!categories.length">{{$t('planPage.CategoriesAbsent')}}
      <router-link
        :to="'/categories'"
      >{{$t('planPage.AddNewCategory')}}</router-link>
    </p>
    <section v-else>
      <div
        v-for="category in categories"
        :key="category.id"
      >
        <p>
          <strong>{{category.name}}:</strong>
          {{category.spend | currencyView}} {{$t('global.from')}} {{category.limit | currencyView}}
        </p>
        <div class="progress" v-tooltip="category.tooltip">
          <div
            class="determinate"
            :class="[category.progressColor]"
            :style="{width: category.progressPercent + '%'}"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import currencyView from '@/filters/currency-view.filter'
export default {
  name: 'Planning | DY-CRM',
  data () {
    return {
      loading: true,
      categories: [],
      spend: null
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t('menu.Planning')
    }
  },
  computed: {
    ...mapGetters(['info'])
  },
  async mounted () {
    const categories = await this.$store.dispatch('fetchCategories')
    const records = await this.$store.dispatch('fetchRecords')

    // for each category create own list of records
    this.categories = categories.map(category => {
      // list of all records
      const spend = records
        // filter records under chosen category
        .filter(record => record.categoryId === category.id)
        // filter records under type in chosen category
        .filter(record => record.type === 'outcome')
        // sum all outcome records fo chosen category
        .reduce((total, record) => total + +record.amount, 0)

      // get percent and fix possible bug with over 100% spends in progressPercent
      const percent = spend / category.limit * 100
      const progressPercent = percent > 100 ? 100 : percent

      // get color of progress bar
      const progressColor = percent < 60
        ? 'green'
        : percent < 95
          ? 'orange'
          : 'red'

      // tooltip data
      const tooltipValue = category.limit - spend
      const tooltip = `${tooltipValue < 0 ? this.$t('Limit exceeded by:') : this.$t('Rest:')} ${currencyView(Math.abs(tooltipValue))}`

      return {
        ...category,
        progressPercent,
        progressColor,
        spend,
        tooltip
      }
    })

    this.loading = false
  }
}
</script>

<style scoped>

</style>
